.icons{
    color: var(--marrom);
    width: 50px;
    height: 50px;
    margin: 30px;

    transition: 0.3s;
}

.icons:hover{
    color: var(--verde);
    transition: 0.3s;
}

.containerMD{
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 250px;
}