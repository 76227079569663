/* .body{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
} */

.containerGeral{
    margin-top: 600px;
}

*{
    margin: 0;
    padding: 0;
    text-decoration: none;
    box-sizing: border-box;
}

@media (min-width: 10px) {
    .containerQS{
        margin-top: 100px;
        margin-bottom: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .titulo{
        font-size: 30px;
        color: rgb(53, 37, 24);
        
    }
    
    .texto{
        margin: 40px 0;
        padding: 0px 40px;
        font-size: 20px;
    }
    
    #logo{
        margin-top: 40px;
        width: 200px;
        border-radius: 100%;
    }
}

@media (min-width: 768px) {

    
    .containerQS{
        margin-top: -180px;
        margin-bottom: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .titulo{
        margin-top: 200px;
        font-size: 60px;
        color: rgb(53, 37, 24);
        
    }
    
    .texto{
        margin: 30px 0;
        padding: 0px 90px;
        font-size: 20px;
    }
    
    #logo{
        margin-top: 40px;
        width: 200px;
        border-radius: 100%;
    } 
}




