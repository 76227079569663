@media (min-width: 10px) {
    .produto {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 300px; /* Largura ajustada */
        padding: 30px;
        background-color: white;
        border-radius: 20px;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
        margin: 50px; /* Espaço entre os produtos */
    }

    #imgProduto {
        width: 100%;
        height: 100%;
        object-fit: cover; /* Preenche o contêiner sem distorção */
        border-radius: 20px; /* Borda arredondada para a imagem */
    }

    .containerImgProduto {
        width: 270px;
        height: 230px; /* Define a altura fixa */
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px; /* Mesma borda arredondada para o contêiner */
    }

    #tituloProduto {
        color: black;
        padding: 5px;
        font-size: 20px;
        text-align: center;
    }

    .produtosPage {
        margin-top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px; /* Espaçamento entre os produtos */
        flex-wrap: wrap; /* Permite quebrar a linha para novos produtos em telas menores */
    }

    .containerPD {
        margin-top: 100px;
        margin-bottom: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}

@media (min-width: 768px) {
    .produto {
        width: 450px;
    }

    #imgProduto {
        width: 400px;
        border-radius: 20px; /* Mesma borda arredondada */
    }
}
