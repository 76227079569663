:root {
    --marrom: rgb(53, 37, 24);
    --verde: rgb(113, 149, 82);
    --cinza: rgb(234, 230, 218);
}

@media (min-width: 10px) {
    .containerNV {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: 1200px; 

        background-color:white;
    }

    .navbar{
        display: flex;
        flex-direction: column;

        justify-content: center;
        align-items: center;

        padding: 20px 40px 20px 20px;
        
        
    }

    .logo{
        height: 70px;
        width: 70px;
        border-radius: 100%;

        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

        margin-bottom: 20px;
    }


    .hrefs{
        font-size: 12px;
        text-decoration: none;

        margin: 20px;
        
        color: var(--marrom);
        

        transition: 0.5s;

        font-weight: bold;

        border-bottom: 1px solid var(--marrom);
        
    }

    .hrefs:hover{
        color:var(--verde);
        font-size: 17px;

        border-bottom: 1px solid var(--verde);
    } 
}
 
@media (min-width: 768px) {
    .containerNV {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: 1200px; 

        background-color:white;
    }

    .navbar{
        display: flex;
        flex-direction: column;

        justify-content: center;
        align-items: center;

        padding: 40px 60px 40px 40px;
        
        
    }

    .logo{
        height: 70px;
        width: 70px;
        border-radius: 100%;

        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

        margin-bottom: 20px;
    }


    .hrefs{
        font-size: 15px;
        text-decoration: none;

        margin: 30px;
        
        color: var(--marrom);
        

        transition: 0.5s;

        font-weight: bold;

        border-bottom: 1px solid var(--marrom);
        
    }

    .hrefs:hover{
        color:var(--verde);
        font-size: 17px;

        border-bottom: 1px solid var(--verde);
    } 
}

