@media (min-width: 10px) {
  .image-gallery {
    margin-top: 200px;
    margin-bottom: 100px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .main-image img {
    width: 260px;
    height: auto;
    margin-bottom: 20px;
  }
  
  .thumbnail-images {
    display: flex;
    justify-content: center;
  }
  
  .thumbnail-images img {
    width: 70px;
    height: auto;
    margin: 0 10px;
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.3s;
  }
  
  .thumbnail-images img.active,
  .thumbnail-images img:hover {
    opacity: 1;
  }
  
  .vendasProduto{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    margin-bottom: 300px;
    
  }

  .linkCompra{
    text-decoration: none;

    margin: 10px;
    padding: 10px 15px 10px 15px;

    border-radius: 30px;

    color: black;
    background-color: white;

    transition: 0.5s;

    font-weight: bold;

    font-size: 25px;
  }

  .linkCompra:hover{
      color:var(--verde);
      background-color: var(--cinza);
  }

  .valor{
      margin-bottom: 30px;
      font-size: 40px;
  }
}

@media (min-width: 768px) {
  .image-gallery {
    margin-top: 300px;
    margin-bottom: 300px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .main-image img {
    width: 500px;
    height: auto;
    margin-bottom: 20px;
  }
  
  .thumbnail-images {
    display: flex;
    justify-content: center;
  }
  
  .thumbnail-images img {
    width: 100px;
    height: auto;
    margin: 0 10px;
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.3s;
  }
  
  .thumbnail-images img.active,
  .thumbnail-images img:hover {
    opacity: 1;
  }
  
  .vendasProduto{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    
  }

  .linkCompra{
    text-decoration: none;

    margin: 10px;
    padding: 10px 15px 10px 15px;

    border-radius: 30px;

    color: black;
    background-color: white;

    transition: 0.5s;

    font-weight: bold;

    font-size: 25px;
  }

  .linkCompra:hover{
      color:var(--verde);
      background-color: var(--cinza);
  }

  .valor{
      margin-bottom: 30px;
      font-size: 40px;
  }
}