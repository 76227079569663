:root {
    --marrom: rgb(53, 37, 24);
    --verde: rgb(113, 149, 82);
    --cinza: rgb(234, 230, 218);
}

@media (min-width: 10px) {
    .slider{
        margin: -350px auto;
        margin-bottom: 100px;
        width: 250px;
        height: 150px;
        overflow: hidden;
    
    }
    
    .slides{
        width: 400%;
        height: 400px;
        display: flex;
        
    }
    
    .slides input{
        display: none;
    }
    
    .slide{
        width: 25%;
        position: relative;
        transition: 2s;
    }
    
    .slide img{
        width: 250px;
    }
    
    .manual-navigation{
        position: absolute;
        width: 250px;
        margin-top: 110px;
        display: flex;
        justify-content: center;
    
    }
    
    .manual-btn{
        border: 1px solid var(--marrom);
        padding: 2px;
        border-radius: 10px;
        cursor: pointer;
        transition: 1s;
    }
    
    .manual-btn:not(:last-child){
        margin-right: 40px;
    }
    
    .manual-btn:hover{
        background-color: var(--verde);
    }
    
    #radio1:checked ~ .first{
        margin-left: 0;
    }
    #radio2:checked ~ .first{
        margin-left: -25%;
    }
    #radio3:checked ~ .first{
        margin-left: -50%;
    }
    
    .navigation-auto div{
        border: 1px solid var(--verde);
        padding: 2px;
        border-radius: 10px;
        cursor: pointer;
        transition: 1s;
    }
    
    .navigation-auto{
        position: absolute;
        width: 250px;
        margin-top: 110px;
        display: flex;
        justify-content: center;
    
    }
    
    .navigation-auto div:not(:last-child){
        margin-right: 40px;
        
    }
    
    .radio1:checked ~ .navigation-auto .auto-btn1{
        background-color: var(--verde);
    }
    .radio2:checked ~ .navigation-auto .auto-btn2{
        background-color: var(--verde);
    }
    .radio3:checked ~ .navigation-auto .auto-btn3{
        background-color: var(--verde);
    }
}

@media (min-width: 768px) {
    .slider{
        margin: -300px auto;
        margin-bottom: 60px;
        width: 600px;
        height: 400px;
        overflow: hidden;
    
    }
    
    .slides{
        width: 400%;
        height: 400px;
        display: flex;
        
    }
    
    .slides input{
        display: none;
    }
    
    .slide{
        width: 25%;
        position: relative;
        transition: 2s;
    }
    
    .slide img{
        width: 600px;
    }
    
    .manual-navigation{
        position: absolute;
        width: 600px;
        margin-top: 260px;
        display: flex;
        justify-content: center;
    
    }
    
    .manual-btn{
        border: 2px solid var(--marrom);
        padding: 5px;
        border-radius: 10px;
        cursor: pointer;
        transition: 1s;
    }
    
    .manual-btn:not(:last-child){
        margin-right: 40px;
    }
    
    .manual-btn:hover{
        background-color: var(--verde);
    }
    
    #radio1:checked ~ .first{
        margin-left: 0;
    }
    #radio2:checked ~ .first{
        margin-left: -25%;
    }
    #radio3:checked ~ .first{
        margin-left: -50%;
    }
    
    .navigation-auto div{
        border: 2px solid var(--verde);
        padding: 5px;
        border-radius: 10px;
        cursor: pointer;
        transition: 1s;
    }
    
    .navigation-auto{
        position: absolute;
        width: 600px;
        margin-top: 260px;
        display: flex;
        justify-content: center;
    
    }
    
    .navigation-auto div:not(:last-child){
        margin-right: 40px;
        
    }
    
    .radio1:checked ~ .navigation-auto .auto-btn1{
        background-color: var(--verde);
    }
    .radio2:checked ~ .navigation-auto .auto-btn2{
        background-color: var(--verde);
    }
    .radio3:checked ~ .navigation-auto .auto-btn3{
        background-color: var(--verde);
    }
}

@media (min-width: 1024px) {
    .slider{
        margin: -300px auto;
        margin-bottom: 100px;
        width: 800px;
        height: 400px;
        overflow: hidden;
    
    }
    
    .slides{
        width: 400%;
        height: 400px;
        display: flex;
        
    }
    
    .slides input{
        display: none;
    }
    
    .slide{
        width: 25%;
        position: relative;
        transition: 2s;
    }
    
    .slide img{
        width: 800px;
    }
    
    .manual-navigation{
        position: absolute;
        width: 800px;
        margin-top: 350px;
        display: flex;
        justify-content: center;
    
    }
    
    .manual-btn{
        border: 2px solid var(--marrom);
        padding: 5px;
        border-radius: 10px;
        cursor: pointer;
        transition: 1s;
    }
    
    .manual-btn:not(:last-child){
        margin-right: 40px;
    }
    
    .manual-btn:hover{
        background-color: var(--verde);
    }
    
    #radio1:checked ~ .first{
        margin-left: 0;
    }
    #radio2:checked ~ .first{
        margin-left: -25%;
    }
    #radio3:checked ~ .first{
        margin-left: -50%;
    }
    
    .navigation-auto div{
        border: 2px solid var(--verde);
        padding: 5px;
        border-radius: 10px;
        cursor: pointer;
        transition: 1s;
    }
    
    .navigation-auto{
        position: absolute;
        width: 800px;
        margin-top: 350px;
        display: flex;
        justify-content: center;
    
    }
    
    .navigation-auto div:not(:last-child){
        margin-right: 40px;
        
    }
    
    .radio1:checked ~ .navigation-auto .auto-btn1{
        background-color: var(--verde);
    }
    .radio2:checked ~ .navigation-auto .auto-btn2{
        background-color: var(--verde);
    }
    .radio3:checked ~ .navigation-auto .auto-btn3{
        background-color: var(--verde);
    }
}